<template>
    <div v-html="innerContent">
    </div>
</template>

<script>
export default {
    name : 'PrivacyProtection',
    data(){
        return {
            innerContent : '',
        }
    },
    created() {
        this.fetchData('/getPrivacyprotection', (data)=>{
            this.innerContent = data.content;
        })
    },
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_terms.css"></style>